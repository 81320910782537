<template>
    <div class="section-wrap">
        <DotLoader :loading="loading" :loaderText="loaderText" :onLoadingComplete="handleLoaderComplete" />
        <StepHeader :stepDetails=stepHeaderConfig :stepHeading="contactBillingInfoHeaderTitle" :contactInfo="this.getPartnerContactInfo()" :imageName="step3SideImgSource" ref="stepHeader"></StepHeader>
        <div class="step-body">
            <div class="form-inner">
                <v-container>
                    <v-row justify="center">
                        <v-col cols="12">
                            <v-card flat class="spacing-playground v-card-form">
                                <v-card-text>
                                    <v-row justify="center">
                                        <v-col cols="12" md="7" class="form-col-section">
                                                        <ContactInfo ref="contactInfo" @focusHeader="focusHeader" @zipGetFastQuote="zipGetFastQuote" @disableNextParentBtn="disabledBtn"></ContactInfo>
                                                        <!-- <SelectPaymentMethod></SelectPaymentMethod> -->
                                                        <PaymentInfo @setPaymentRequestList="setPaymentRequestList" @disableNextParentBtn="disabledBtn" @setEmployeeInfoLoader="employeeInfoLoader" @setGetFastQuoteLoader="fastQuoteLoader" ref="paymentInfo"></PaymentInfo>
                                        </v-col>
                                        <v-col cols="12" md="5" class="side-col-section" v-show="$vuetify.breakpoint.smAndUp">
                                                <v-card color="ml_lightgray" outlined elevation="0"  class="side-card">
                                                    <v-container>
                                                        <v-row>
                                                            <v-col cols="10" offset="1" class="text-center">
                                                                <v-card-title>
                                                                    <v-img 
                                                                        alt="Snoopy"
                                                                        class="shrink side-card-img"
                                                                        contain
                                                                        :src="step3SideImgSource"
                                                                        transition="scale-transition"
                                                                        max-height="212"
                                                                        width="auto"
                                                                        v-if="step3SideImgSource !== ''"
                                                                    />
                                                                    
                                                                    
                                                                </v-card-title>
                                                               <v-card-text>
                                                                    <div class="price-value xxl" aria-live="polite">
                                                                            <span class="currency font-weight-bold">{{ getPriceSplit( calculateTotals() )[0] }}</span>
                                                                            <span class="units font-weight-bold">{{ getPriceSplit( calculateTotals() )[1]}}</span>
                                                                            <span class="cents font-weight-bold">{{ getPriceSplit( calculateTotals() )[2]}}
                                                                                <span class="payment-frequency d-flex flex-column align-start pl-2">Monthly <br /> Premium</span>
                                                                            </span>
                                                                    </div>
                                                                    <div class=" text-left d-flex flex-row justify-start align-start" v-for="discount in getSavedDiscountsData()" :key="discount.discountId">
                                                                            <v-icon class="ml_green--text mt-n1 mr-1"> mdi-check </v-icon> <span class="ml_gray--text text--darken-4">{{ discount.name }}</span>
                                                                    </div>
                                                                    <DotLoader :loading="isLoadingQuote" />
                                                               </v-card-text>
                                                               <v-card-title class="pt-2">
                                                                    <v-row class="d-flex justify-center mb-5">
                                                                        <v-col cols="6">
                                                                            <v-divider></v-divider>
                                                                        </v-col>                                                                    
                                                                    </v-row>

                                                                    <p role="heading" aria-level="2" v-html="renderContactBillingInfoSidebarTitle"></p>
                                                               </v-card-title>

                                                                <v-card-actions class="d-flex flex-column black--text">
                                                                    <v-btn block x-large class="nextBtnFocus" color="ml_purple" @click="goNext" :disabled="btnDisabled">
                                                                        Next Step
                                                                        <v-icon small>mdi-play</v-icon>
                                                                    </v-btn>

                                                                    <p class="pt-3 pb-7 support-phone">Or call: <a :href="'tel:'+this.getPartnerContactInfo()" v-on:click="trackAdobeEventPhone()"> {{this.getNumbertoAlphanumberic(this.getPartnerContactInfo())}}</a></p>
                                                                    <v-icon x-large>$medal</v-icon>
                                                                    <h4 role="heading" aria-level="2" class="support-heading"> <br>
                                                                        {{ 
                                                                            dynamicContent && dynamicContent.contactBillingInfoSidebarAwardTitle
                                                                                ? dynamicContent.contactBillingInfoSidebarAwardTitle 
                                                                                : defaultDynamicContent.contactBillingInfoSidebarAwardTitle
                                                                        }}
                                                                    </h4>
                                                                    <p class="support-note" v-html="dynamicContent && dynamicContent.contactBillingInfoSidebarAwardBlockHtml ? dynamicContent.contactBillingInfoSidebarAwardBlockHtml : defaultDynamicContent.contactBillingInfoSidebarAwardBlockHtml"></p>
                                                                </v-card-actions>
                                                            </v-col>
                                                        </v-row>
                                                    </v-container>
                                                </v-card>    
                                            </v-col>
                                    </v-row>
                                </v-card-text>
                               
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>
            </div>
        </div>
        <v-footer class="v-footer" ref="controlZoomLevel" app color="white" elevation="4" role="none">
            <v-row class="d-flex justify-center align-center">
                <v-col md="auto" cols="12" class="d-flex justify-center align-center pb-0">
                    <v-row class="fill-height" no-gutters>
                        <v-col v-show="$vuetify.breakpoint.smAndDown">
                            <v-card-text class="px-0 pb-0">
                                <div class="price-value sm justify-start" aria-live="polite">
                                    <span class="currency font-weight-bold">{{ getPriceSplit( calculateTotals() )[0] }}</span>
                                    <span class="units font-weight-bold">{{ getPriceSplit( calculateTotals() )[1]}}</span>
                                    <span class="cents font-weight-bold">{{ getPriceSplit( calculateTotals() )[2]}}
                                        <span class="payment-frequency d-flex flex-column align-start pl-2">Monthly <br /> Premium</span>
                                    </span>
                                </div>
                                <span class="ml_blue--text d-flex flex-row justify-center align-center" style="font-size: 10px; line-height: 10px;"> <v-icon class="ml_green--text mt-n1"> mdi-check </v-icon>{{ this.getDiscounstApplied() }}
                                    <v-menu v-model="showDiscountsApplied" top :offset-y="offsetTipY"  :close-on-content-click="closeTipOnClick">
                                        <template v-slot:activator="{on, attrs }">
                                            <v-btn x-small v-bind="attrs" icon v-on="on"> <v-icon>mdi-chevron-up</v-icon></v-btn>
                                        </template>
                                        <v-card>
                                            <v-card-title>Discount{{ getSavedDiscountsData().length > 1 ? 's':'' }} Applied:</v-card-title>
                                            <v-divider></v-divider>
                                            <v-card-text class="py-3"  v-for="discount in getSavedDiscountsData()" :key="discount.discountId">
                                                <v-icon class="ml_green--text mt-n1 mr-1"> mdi-check </v-icon> {{ discount.name }}
                                            </v-card-text>
                                        </v-card>
                                    </v-menu>
                                </span>
                            </v-card-text>
                        </v-col>
                        <v-col cols="auto" class="px-2"  v-show="$vuetify.breakpoint.smAndDown">
                                <v-divider vertical></v-divider>
                        </v-col>
                        <v-col class="body-2 align-self-center text-center">
                            {{ getNextStepDesc() }}
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="auto">
                    <v-btn @click="goBack">Back</v-btn>
                </v-col>
                <v-col cols="auto">
                    <v-btn color="ml_green" tabindex="0" @click="goNext" :disabled="btnDisabled">
                        Next Step <v-icon class="mt-n1">mdi-menu-right</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-footer>
    </div>
</template>

<script>
    import StepHeader from '../../components/challenger/shared/StepHeader.vue';
    import ContactInfo from '../../components/global/ContactInfo.vue';
    import PaymentInfo from "../../components/global/PaymentInfo.vue";
    import SelectPaymentMethod from "../../components/global/SelectPaymentMethod.vue";
    import DotLoader from '../../components/shared/DotLoader.vue';

    import customerDetails from '../Global/JS/customerDetails.js';

    export default {
        mixins: [customerDetails],
        components: { ContactInfo, PaymentInfo, SelectPaymentMethod, DotLoader, StepHeader },
        data () {
            return {
                paymentRequestList: null,
                loading: false,
                loaderText: "",
                stepHeaderConfig: [],
                onLoderComplete: () => {},
                btnDisabled: false, 
                showDiscountsApplied: false,
                closeTipOnClick: true,
                offsetTipY: true,
                isLoadingQuote: false, 
                isLoadingQuoteText: "Loading Quote, Please wait",


            }
        },
        created() {
            this.stepHeaderConfig = {
                stepHeading: this.dynamicContent && this.dynamicContent.contactBillingInfoHeaderTitle ? this.dynamicContent.contactBillingInfoHeaderTitle : this.defaultDynamicContent.contactBillingInfoHeaderTitle,
                currentStep: 3,
                totalSteps: 5
            };

            this.setImageSource(this.defaultSidebarImage, this.dynamicSidebarImage, "3", "setStep3SideImgSource");
        },
        mounted() {
            if(this.employeeToken && !this.isMetGen && this.partner.billingOptions === 'both'){
                if(!this.IAICPayOptionSetUp) {
                    this.$store.commit("setPayOption", 'both');
                    this.$store.commit("setIAICPayOptionSetUp", true);
                }
            }
            this.$store.dispatch("createOrUpdateLeadCustomerInfo")
                .then(() => {
                    this.$store.dispatch("createOrUpdateLeadCustomerActivity")
                }
            );

            window.liQ = window.liQ || [];
            // Optimization: removing QuantumServe.com Call 
            // window._qevents = window._qevents || [];
            // (function() {
            //     var elem = document.createElement('script');
            //     elem.src = (document.location.protocol == "https:" ? "https://secure" : "http://edge") + ".quantserve.com/quant.js";
            //     elem.async = true;
            //     elem.type = "text/javascript";
            //     var scpt = document.getElementsByTagName('script')[0];
            //     scpt.parentNode.insertBefore(elem, scpt);
            // })();
            // window._qevents.push({
            //     "qacct": "p-800TOflix8dOQ",
            //     "labels": "_fp.event.PageView",
            //     "event": "refresh",
            //     "uid": this.$store.getters.owner.email
            // });
            
            // window._qevents = window._qevents || [];
            // window._qevents.push({
            //     "qacct": "p-800TOflix8dOQ",
            //     "labels": "_fp.event.Pet Insurance Quote: Step 3",
            //     "event": "refresh"
            // });
        },
        computed: {
            partner: function () {
                return this.$store.getters.partner;
            },
            employeeToken: function () {
                return this.$store.getters.employeeToken;
            },
            isMetGen: function(){
                return this.$store.getters.isMetGen;
            },
            leadId() {
                return this.$store.getters.leadId;
            },
            pets: function () {
                return this.$store.getters.pets;
            },
            isFamilyPlan: function () {
                return this.$store.getters.isFamilyPlan;
            },
            selectedPolicies() {
                return this.$store.getters.selectedPolicies;
            },
            availableDiscounts: function () {
                return this.$store.state.discounts.options;
            },
            savedDiscounts: function () {
                return this.$store.state.discounts.selection;
            },
            defaultDynamicContent() {
                return this.$store.getters.getDefaultDynamicContent.DynamicJson;
            },
            dynamicContent() {
                return this.$store.getters.getDynamicContent.DynamicJson;
            }, 
            renderContactBillingInfoSidebarTitle() {
                return this.dynamicContent && this.dynamicContent.contactBillingInfoSidebarTitle
                    ? this.interpolateContent(this, this.dynamicContent.contactBillingInfoSidebarTitle) 
                    : this.interpolateContent(this, this.defaultDynamicContent.contactBillingInfoSidebarTitle);
            },
            contactBillingInfoHeaderTitle() {
                return this.defaultDynamicContent && this.defaultDynamicContent.contactBillingInfoHeaderTitle 
                    ? this.dynamicContent && this.dynamicContent.contactBillingInfoHeaderTitle 
                        ? this.dynamicContent.contactBillingInfoHeaderTitle 
                        : this.defaultDynamicContent.contactBillingInfoHeaderTitle
                    : '';
            },
            currentPet() {
                return this.$store.getters.currentPet;
            },
        },
        methods: {
            setPaymentRequestList: function (request) {
                this.paymentRequestList = request;
            },
            getPetsNames() {
                if (this.isFamilyPlan) { 
                    return "your family"
                } else {
                    const length = this.pets.length;
                    if(length === 1) return this.pets[0].name;
                    if(length === 2) return this.pets[0].name + " and " + this.pets[1].name;
                    if(length === 3) return this.pets[0].name + ", " + this.pets[1].name + " and " +  this.pets[2].name;
                }
            },
            focusHeader() {
                this.$nextTick(() => this.$refs.stepHeader.focus());
            },
            goBack() {
                if (this.paymentRequestList !== null) {
                    this.paymentRequestList = null;
                    this.$store.commit("setPaymentRequestList", null);
                }

                this.$store.commit("setCurrentPage","Plan Selection");
                this.$store.commit("clearPolicies");
                this.redirectToView("quotes");
            },
            async goNext() {
                if(this.paymentRequestList === null) await this.$refs.paymentInfo.generateSubmissionRequest(this.$refs.contactInfo)

                if(!await this.$refs.contactInfo.submitContactInfo() || this.paymentRequestList === null) {
                    setTimeout(() => this.$refs.contactInfo.submitContactInfo(), 100);
                } else {
                    await this.$store.commit("setPaymentRequestList", this.paymentRequestList);
                    await this.setLoader(true, "Submitting payment, Please wait");

                    this.$store.commit('setStepComplete', 'billing');
                    this.$store.commit('setStepComplete', 'payment');

                    this.$store.commit("setCurrentPage", "Confirmation Page");
                    
                    let ldo = {
                        eventName: 'quoteStep',
                        attributes: { 
                        application: 'MLPI',
                            form: 'Pet Insurance Quote',
                            stepName: 'Contact & Billing Info',
                            providerName: this.pets[0].hasOwnProperty('provider') ? this.pets[0].provider : "",
                            leadId: this.leadId,
                            petType: this.getPetsSpecies(this.pets),
                            standardWellness: this.getStandardWellness(this.selectedPolicies, this.isFamilyPlan, this.pets),
                            familyPlan: this.isFamilyPlan ? 'Yes' : 'No',
                        }
                    };

                    ldo = this.popullateFederalAnalyticProperty(ldo);

                    this.$store.dispatch("trackAnalyticsEvent",{
                        eventType:"quoteStep",
                        ldo: ldo
                    });

                    this.redirectToView("summary");
                }
            },
            redirectToView(step) {
                setTimeout(() => this.routerPush(step), 700);
            },
            handleLoaderComplete() {
                if(this.onLoderComplete) {
                    this.onLoderComplete();
                    this.onLoderComplete = () => {};
                }
            },
            async setLoader(loading, loaderText, loaderCompleteCallback) {
                this.loading = loading;
                this.loaderText = loaderText;
                
                if(loaderCompleteCallback) {
                    this.onLoderComplete = null;
                    this.onLoderComplete = () => { 
                        loaderCompleteCallback();
                    }
                }
            },
            trackAdobeEventPhone() {
                let ldo = {
                    eventName: 'linkClick',
                    attributes: {
                        application: 'MLPI',
                        form: 'Pet Insurance Quote',
                        linkName: this.getPartnerContactInfo(),
                        leadId: this.leadId,
                        stepName: 'Contact & Billing Info'
                    }
                };
                digitalData.eventTrack('linkClick', ldo);
            }, 
            getNextStepDesc() {
                let desc = "Next, please review and confirm your plan.";
                if(this.pets.length > 1 && !this.isFamilyPlan){
                    desc = "Next, please review and confirm your plans.";
                }

                return this.dynamicContent && this.dynamicContent.contactBillingInfoFooterInstructions
                    ? this.dynamicContent.contactBillingInfoFooterInstructions 
                    : this.defaultDynamicContent.contactBillingInfoFooterInstructions;
            },
            disabledBtn(disable) {
                this.btnDisabled = disable;
            },
            calculateTotals: function () {
                let total = [];

                if (this.selectedPolicies.length > 0) {
                    Object.entries(this.selectedPolicies).forEach(([key, val]) => {
                        total.push(parseFloat(val.planDetails.monthlyPremium))
                    });
                }

                //TODO: this function would work if we get a number instead of a string $ ##.## /mo > data needs to change or received in actual integer
                return total.reduce(function (total, num) { return total + num }, 0);
            },
            getSavedDiscountsData(){
                return this.availableDiscounts.filter( discount => {
                    return this.savedDiscounts.includes(discount.discountId);
                })
            },
            async employeeInfoLoader(loading){
                if(loading) {
                    this.btnDisabled = loading;
                    await this.setLoader(loading, "Validating your information, Please wait");
                } else {
                    this.btnDisabled = loading;
                    await this.setLoader(loading);
                }
                
            },
            async fastQuoteLoader(loading){
                if(loading) {
                    this.btnDisabled = loading;
                    this.isLoadingQuote = loading;
                } else {
                    this.btnDisabled = loading;
                    this.isLoadingQuote = loading;
                }
            },
            async zipGetFastQuote(){
                await this.$refs.paymentInfo.getFastQuote();
                await this.$refs.paymentInfo.calculateDates();
            }
        }
    }
</script>